/**
 * This is a shim to load our routes at runtime, allowing us to generate
 * routes separate from the bundled assets. This is useful because we
 * can then ship the same asset bundle in different environments without
 * needing to recompile for the different routes file in use.
 */

import axios from "axios";

export default (new class {
   #envRoutes;
   #promise;

   constructor() {
      this.#promise = axios.get("/routes.json").then((response) => {
         this.#envRoutes = response.data;
      });
   }

   async getRoutes() {
      if (!this.#envRoutes) {
         await this.#promise;
      }
      if (!this.#envRoutes) {
         throw new Error("Routes not loaded");
      }
      return this.#envRoutes;
   }
})();
